import {MembersModal} from '@wix/wix-events-commons-statics/dist/members'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {getLanguage} from '../../commons/selectors/environment'
import {openModal} from '../../commons/services/modal'
import {GetState, StoreExtraArgs} from '../types/state'

export const PROMPT_LOGIN = createActions('PROMPT_LOGIN')
export const LOAD_MEMBERS_FOR_EVENTS = createActions('LOAD_MEMBERS_FOR_EVENTS')
export const OPEN_MEMBERS_MODAL = 'OPEN_MEMBERS_MODAL'

export const ensureLoggedIn = () => async (dispatch, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
  if (!wixCodeApi.user.currentUser.loggedIn) {
    return dispatch(promptLogin())
  }
  return true
}

export const promptLogin = () => (dispatch, getState: GetState) =>
  dispatch(callAPI(PROMPT_LOGIN, getLanguage(getState())))

export const loadMembersForEvents = () => (dispatch, getState: GetState) =>
  dispatch(callAPI(LOAD_MEMBERS_FOR_EVENTS, getState().events.map(event => event.id)))

export const openMembersModal = (eventId: string) => async (
  dispatch,
  getState: GetState,
  {wixCodeApi, compId, baseUrl}: StoreExtraArgs,
) => {
  const loggedIn = await dispatch(ensureLoggedIn())
  if (loggedIn) {
    dispatch({
      type: OPEN_MEMBERS_MODAL,
      payload: {
        eventId,
      },
    })
    openModal({
      type: MembersModal.LIST,
      wixCodeApi,
      baseUrl,
      compId,
      params: {
        eventId,
      },
    })
  }
}
