import {
  createUouBiMiddlewareWithBiParams,
  createUsersBiMiddlewareWithBiParams,
} from '@wix/wix-events-commons-statics/dist/bi/init'
import {BiParams} from '@wix/wix-events-commons-statics/dist/bi/interfaces'
import {isRtlLanguage} from '@wix/wix-events-commons-statics/dist/locale'
import {IWidgetControllerConfig, IWixAPI} from 'native-components-infra/dist/src/types/types'
import {bindActionCreators} from 'redux'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {getLanguage} from '../../commons/selectors/environment'
import {getPageInfo, isSSR} from '../../commons/utils/wix-code-api'
import {isMembersEventsPageInstalled} from '../../members-page/utils/members-api'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from '../actions/calendar-layout'
import {updateComponent, updateComponentDraft, updateStyleParams} from '../actions/component'
import {createEvent} from '../actions/event'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from '../actions/layout'
import {loadMembersForEvents, openMembersModal} from '../actions/members'
import {navigateToPage} from '../actions/navigate-to-page'
import {updateSettings, updateStyle} from '../actions/sdk'
import * as eventsUou from '../bi/uou-bi-events-map'
import * as eventsUsers from '../bi/users-bi-events-map'
import reducers from '../reducers'
import {sortEvents} from '../reducers/events'
import {createWidgetFedopsLogger} from '../services/fedops'
import {Actions, State} from '../types/state'
import {Api} from '../utils/api'
import {createReduxStore} from '../utils/store'

export const createWidgetController = async (controller: IWidgetControllerConfig) => {
  return Promise.resolve({
    pageReady: async () => await pageReady(controller),
  })
}

const pageReady = async (controller: IWidgetControllerConfig) => {
  const fedopsLogger = createWidgetFedopsLogger(controller)

  const serverApi = new Api(controller)
  const pageInfo = await getPageInfo(controller.wixCodeApi)
  const pageUrl = await controller.wixCodeApi.site.getSectionUrl({sectionId: 'events'})
  const store = await createStore(controller, serverApi)
  const state = store.getState()

  const props = {
    pageUrl,
    cssBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
    pageInfo,
    state,
    actions: exportedActions({store, appLoaded: fedopsLogger.onAppLoaded}),
    isRTL: isRtlLanguage(getLanguage(state)),
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(<any>loadMembersForEvents())
    })
  }

  controller.setProps(props)

  if (isSSR(controller.wixCodeApi)) {
    fedopsLogger.onSSRPageReady()
  }
}

const createBiMiddleware = (biParams: BiParams) => [
  createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
  createUouBiMiddlewareWithBiParams(biParams, eventsUou),
]

const createStore = async (controller: IWidgetControllerConfig, serverApi: Api) => {
  const {initialData, user} = await getInitialData(serverApi, controller.wixCodeApi)

  const middleware = createBiMiddleware({
    wixCodeApi: controller.wixCodeApi,
    platformAPIs: controller.platformAPIs,
    appParams: controller.appParams,
    compId: controller.compId,
    user,
  })

  const store = createReduxStore({
    serverApi,
    reducers,
    initialData,
    wixCodeApi: controller.wixCodeApi,
    compId: controller.compId,
    middleware,
  })

  await store.dispatch(updateStyleParams(controller.config.style.styleParams))
  await store.dispatch(<any>setBaseEnvironment())

  const onStateChange = () => {
    const state = store.getState()
    controller.setProps({state})
  }

  store.subscribe(onStateChange)

  return store
}

const getInitialData = async (
  serverApi: Api,
  wixCodeApi: IWixAPI,
): Promise<{initialData: Partial<State>; user: object}> => {
  const membersEnabled = await isMembersEventsPageInstalled(wixCodeApi)
  const {multilingual} = wixCodeApi.window
  const {
    experiments,
    component: {events, config, id},
    siteSettings,
    demoEvents,
    translation,
    user,
  } = await serverApi.getAppData(membersEnabled)

  return {
    initialData: {
      events: sortEvents(events),
      siteSettings,
      demoEvents,
      multilingual: {
        currentLanguage: multilingual.currentLanguage,
        isEnabled: multilingual.isEnabled,
      },
      experiments,
      translation,
      component: {
        ...config,
        id,
      },
      membersEnabled,
    },
    user,
  }
}

const exportedActions = ({store, appLoaded}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    createEvent,
    updateSiteSettings,
    updateComponent,
    updateComponentDraft,
    navigateToPage,
    updateStyle,
    updateStyleParams,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return actions
}
