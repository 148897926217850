import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  EVENTS_APP_ID,
  MEMBERS_APP_DEF_ID,
  MEMBERS_PAGE_SECTION_ID,
} from '@wix/wix-events-commons-statics/dist/constants/app-definitions'

export const getMembersAPI = (wixCodeApi: IWixAPI) => wixCodeApi.site.getPublicAPI(MEMBERS_APP_DEF_ID)

export const isMembersEventsPageInstalled = wixCodeApi =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: EVENTS_APP_ID,
      sectionId: MEMBERS_PAGE_SECTION_ID,
    }),
  )
