import {addMonths, getStartOfDay, getStartOfMonth} from '@wix/wix-events-commons-statics/dist/date/date'
import {AnyAction} from 'redux'
import {
  ADD_CALENDAR_MONTH,
  CLOSE_MONTHLY_CALENDAR_EVENT,
  CLOSE_MONTHLY_CALENDAR_POPUP,
  OPEN_MONTHLY_CALENDAR_EVENT,
  OPEN_MONTHLY_CALENDAR_POPUP,
  SET_CALENDAR_DATE,
  SET_CALENDAR_TODAY,
  SUB_CALENDAR_MONTH,
} from '../actions/calendar-layout'
import {CalendarLayout} from '../types/state'

const defaultState: CalendarLayout = {
  referenceDate: null,
  borderWidth: 1,
  monthly: {
    selectedDate: null,
    selectedEventId: null,
  },
}

export const calendarLayout = (state = defaultState, action: AnyAction): CalendarLayout => {
  switch (action.type) {
    case ADD_CALENDAR_MONTH:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: addMonths(getStartOfMonth(action.payload), 1),
      }
    case SUB_CALENDAR_MONTH:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: addMonths(getStartOfMonth(action.payload), -1),
      }
    case SET_CALENDAR_TODAY:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: getStartOfDay(new Date()),
      }
    case SET_CALENDAR_DATE:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: getStartOfDay(action.payload),
      }
    case OPEN_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: null,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
        },
      }
    case OPEN_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: action.payload.eventId,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedEventId: null,
        },
      }
    default:
      return state
  }
}
