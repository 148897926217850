import {AnyAction} from 'redux'
import {UPDATE_COMPONENT_DRAFT, UPDATE_COMPONENT_TEXTS, UPDATE_STYLE_PARAMS} from '../actions/component'
import {ComponentConfig} from '../types/state'

const defaultState: ComponentConfig = {} as ComponentConfig

export const component = (state = defaultState, action: AnyAction): ComponentConfig => {
  switch (action.type) {
    case UPDATE_STYLE_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
        params: {
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    case UPDATE_COMPONENT_DRAFT.SUCCESS:
      return {
        ...state,
        byEventId: action.payload.component.config.byEventId,
        byStatus: action.payload.component.config.byStatus,
      }
    case UPDATE_COMPONENT_TEXTS:
      return {
        ...state,
        settings: {
          ...state.settings,
          texts: action.payload,
        },
      }
    default:
      return state
  }
}
