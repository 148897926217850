import {EventMap} from '@wix/wix-events-commons-statics/dist/bi/interfaces'
import {AnyAction} from 'redux'
import {CREATE_EVENT} from '../actions/event'
import {State} from '../types/state'

export const eventMap: EventMap = {
  [CREATE_EVENT.REQUEST]: (state: State, action: AnyAction) => ({
    evid: 103,
    event_id: <string>null,
    create_event_button: action.args[1] ? 'ADI' : 'save',
    is_first_event_save: true,
    is_adi_intro: action.args[1],
  }),
  [CREATE_EVENT.SUCCESS]: (state: State, action: AnyAction) => {
    const {
      event: {
        id,
        title,
        scheduling: {config},
        mainImage,
      },
      ADI,
    } = action.payload

    return {
      evid: 104,
      event_id: id,
      event_name: title,
      event_start_date: config.scheduleTbd ? null : new Date(config.startDate).getTime(),
      event_finish_date: config.scheduleTbd || !config.endDate ? null : new Date(config.endDate).getTime(),
      event_timezone: config.scheduleTbd ? null : config.timeZoneId,
      create_event_button: ADI ? 'ADI' : 'save',
      with_image: !!(mainImage && mainImage.id),
      is_first_event_save: true,
      is_adi_intro: ADI,
    }
  },
  endpoint: 'events-users',
}
