import {EventMap} from '@wix/wix-events-commons-statics/dist/bi/interfaces'
import {AnyAction} from 'redux'
import {DOWNLOAD_TICKETS_ACTION} from '../actions/tickets'

export const eventMap: EventMap = {
  [DOWNLOAD_TICKETS_ACTION]: (state, action: AnyAction) => {
    return {
      evid: 88,
      event_id: action.payload.eventId,
    }
  },
  endpoint: 'events-uou',
}
