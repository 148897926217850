import {VisitorType} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {IWixAPI} from 'native-components-infra/dist/src/types/types'
import {isRsvp} from '../../commons/selectors/event'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {navigateToDetailsPage} from '../../commons/services/navigation'
import {isRestrictedTo} from '../reducers/members-enabled'
import {GetState, State} from '../types/state'
import {ensureLoggedIn} from './members'

export const NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE'

export const navigateToPage = (event: wix.events.Event, state: State) => async (
  dispatch,
  getState: GetState,
  {wixCodeApi}: {wixCodeApi: IWixAPI},
) => {
  const detailsPageEnabled = isDetailsPageEnabled(state.siteSettings)
  const isRestricted = !detailsPageEnabled && isRsvp(event) && isRestrictedTo(event, state, VisitorType.MEMBER)
  if (!isRestricted || (await dispatch(ensureLoggedIn()))) {
    await navigateToDetailsPage(event, detailsPageEnabled, wixCodeApi)
    dispatch({type: NAVIGATE_TO_PAGE, payload: {event}})
  }
}
