import {isCalendarLayout} from '../selectors/list-settings'
import {GetState} from '../types/state'
import {calendarSettingsSectionChanged, calendarSettingsTabChanged} from './calendar-layout'

export const settingsTabChanged = (tab: SettingsTab) => (dispatch: Function, getState: GetState) => {
  if (isCalendarLayout(getState())) {
    dispatch(calendarSettingsTabChanged(tab))
  }
}

export const settingsSectionChanged = (id: string) => (dispatch: Function, getState: GetState) => {
  if (isCalendarLayout(getState())) {
    dispatch(calendarSettingsSectionChanged(id))
  }
}

export enum SettingsTab {
  DISPLAY = 'list-display',
}
